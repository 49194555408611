fieldset {
  border: 1px solid;
  border-radius: 5px;
  background-color: #fcfcfc;
  width: '45%';
  min-width: '400px';
  margin-bottom: 15px;
}

legend {
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 5px;
}

fieldset > ul {
  padding: 0 10px;
}

fieldset > ul > li {
  list-style: none;
  margin-bottom: 5px;
}

#atendimento {
  display: flex;
  justify-content: 'space-between';
  gap: 10px;
}

#atendimento > fieldset {
  flex: 1 1 0;
}